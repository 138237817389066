import React from "react";
import Lottie from "react-lottie";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import Header from "../components/header2";

import solutionHero from "../images/homepage/solution-hero-image.svg";
import dunavNet from "../images/homepage/dunav-net.svg";

import serviceOneAnimation from "../animations/modul1.json";
import serviceTwoAnimation from "../animations/modul2.json";
import serviceThreeAnimation from "../animations/modul3.json";
import serviceFourAnimation from "../animations/modul4.json";
import serviceFiveAnimation from "../animations/modul5.json";
import serviceSixAnimation from "../animations/modul6.json";
import serviceSevenAnimation from "../animations/modul7.json";
import serviceEightAnimation from "../animations/modul8.json";
import serviceNineAnimation from "../animations/modul9.json";

import "../styles/index.css";

const defaultOptions = data => ({
  loop: true,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
});

const Hero = () => (
  <div className="hero solutions-hero">
    <div className="container">
      <Header selected="solution"/>

      <div className="site-hero">
        <div className="hero-headline">
          <h1>agroNET</h1>
          <h1>digital farming platform</h1>
          <br />
          <p>
            agroNET is a cloud based platform acting as an interoperability hub
            for all farm operations, enabling complete farm asset and activities
            management and monitoring which is the basic building block of
            digital farming.
          </p>
          <a href="/contact" className="default-btn">
            Contact us
          </a>
          <p className="powered-by">
            <span>Powered by</span>
            <img className="dunav-logo-solution" src={dunavNet} alt="" />
          </p>
        </div>

        <div className="hero-animation-solution">
          <img src={solutionHero} alt=""/>
        </div>
      </div>
    </div>
  </div>
);

const SolutionPage = () => (
  <Layout>
    <SEO title="Solution" keywords={["bixbit", "web-dev", "iot-solutions"]} />
    <Hero />
    <div className="container container-solution">
      <div className="service-item-flex">
        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceOneAnimation)} />
          {/* <img src={serviceOneImage} /> */}
        </div>

        <div className="service-item-flex-text">
          <h3>Irrigation optimization</h3>
          <p>
            Using the irrigation expert module, the number of irrigation cycles
            can be reduced and to ailoredo the crop and soil characteristics as
            well as to the equipment used.{" "}
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>
              Real time monitoring of soil moisture on different depths
              (depending on plant species)
            </li>
            <li>
              Eto calculator – gives the tentative amount of used water
              according to the daily evapotranspiration and plants’ coefficients
            </li>
          </ul>
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-text">
          <h3>Smart pheromone traps</h3>
          <p>
            The aim of the trapNET expert module is to help farmers manage the
            population of insects inorder to avoid overpopulation, thus
            protecting the crop quality and increasing yields.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>
              Real time monitoring of delta pheromone traps - sticky plate with
              caught insects,
            </li>
            <li>
              Automatically counting the number of caught insects (if highly
              selective pheromone is used)
            </li>
          </ul>
        </div>

        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceTwoAnimation)} />
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceThreeAnimation)} />
        </div>

        <div className="service-item-flex-text">
          <h3>Pest and disease prediction and management</h3>
          <p>
            The main purpose of the alertNET module is to help farmers prevent
            plant diseases and hence protect yields and profit.
          </p>
          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>
              Real time monitoring of environmental parameters (air temperature,
              air humidity, precipitation) and leaf wetness; parameters and
              plant disease prediction models;
            </li>
            <li>
              Calculation of daily infection risk for different disease
              according the environmental
            </li>
          </ul>
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-text">
          <h3>Asset management optimization</h3>
          <p>
            boxNET is a comprehensive solution for asset management optimized
            for use in agriculture. It enables real-time asset control and
            management and thus enables process improvement and reduction of
            costs.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>Box pallets position tracking in real time</li>
            <li>Insight in the box pallets stock for each defined location</li>
            <li>
              Alarming in case of irregular situations (loss of signal on the
              gates, inconsistent box pallet location, etc.)
            </li>
          </ul>
        </div>

        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceFourAnimation)} />
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceFiveAnimation)} />
        </div>

        <div className="service-item-flex-text">
          <h3>Machinery management optimization</h3>
          <p>
            The aim of the fleetNET expert module is better managing of
            agriculture machinery. It provides information on position tracking,
            fuel consumption, driving style, service book etc.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>Tracking position,</li>
            <li>Monitoring fuel consumption,</li>
            <li>Monitoring driving style,</li>
            <li>Insight in working hours</li>
          </ul>
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-text">
          <h3>Satellite based field monitor</h3>
          <p>
            The aim of the fieldNET module is increasing the accuracy of
            observation and reducing monitoring costs as well as management
            cost.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>Vitality index analysis,</li>
            <li>NDVI analysis,</li>
            <li>Comparing different fields,</li>
            <li>Comparing fields in different seasons.</li>
          </ul>
        </div>

        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceSixAnimation)} />
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceSevenAnimation)} />
        </div>

        <div className="service-item-flex-text">
          <h3>Recording of field activities</h3>
          <p>
            Field activity register allows simple and easy recording of all
            activities during agricultural production.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>Simple recording of production activities,</li>
            <li>
              Generation of different reports (achieved yields, fertilizer and
              pesticides consumption, working hours’ overview, pests and disease
              appearance...)
            </li>
          </ul>
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-text">
          <h3>Poultry production optimization</h3>
          <p>
            Using the poultryNET expert modules, the environmental conditions as
            well as food and water consumption and prepared feed mixture could
            be tailored to specific animal needs and production goal. Increased
            of energy, optimized food and water consumption and better meat
            quality are the main benefits.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>Environmental condition optimization with instructions</li>
            <li>Feed and water consumption optimization</li>
            <li>Chicken behavioral monitoring</li>
          </ul>
        </div>

        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceEightAnimation)} />
        </div>
      </div>

      <div className="service-item-flex">
        <div className="service-item-flex-image">
          <Lottie options={defaultOptions(serviceNineAnimation)} />
        </div>

        <div className="service-item-flex-text">
          <h3>Grain storage optimization</h3>
          <p>
            Using the grain storage expert module, the number of elevation
            cycles can be reduced and tailored to the grain species and silo
            conditions.
          </p>

          <p>
            <b>Functionalities:</b>
          </p>

          <ul>
            <li>Real time monitoring of environment conditions</li>
            <li>Easy-to-use instruction for activities to be undertaken</li>
            <li>Grain reception and delivery monitoring</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="solutions-bottom-part">
      <div className="container">
        <Footer />
      </div>
    </div>
  </Layout>
);

export default SolutionPage;
